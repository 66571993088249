<template>
	<div class="editorial__master-wrapper">
		<Navigation
			:collapsible="windowWidth <= 1200"
		/>
		<div class="editorial">
			<div class="article">
				<router-view></router-view>
			</div>
			<Footer/>
		</div>
	</div>
</template>

<script>
import Navigation from "./Components/Navigation.vue"
import ScrollToChapter from "@/mixins/ScrollToChapter"
import Footer from "../../components/Footer.vue"

export default {
	components: {
		Navigation,
		Footer
	},
	mixins: [
		ScrollToChapter
	],
	data () {
		return {
			windowWidth: window.innerWidth
		}
	},
	mounted () {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
	}
}
</script>

<style lang="scss">
	.editorial__master-wrapper {
		background: #FAF1E4;
		display: flex;
		flex-direction: row;

		@media only screen and (max-width: 1200px) {
			flex-direction: column;
		}

		h1 {
			color: #000;
			font-size: 38px;
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			letter-spacing: 1px;
			line-height: 1.4;
			margin-bottom: 40px;
			margin-top: 20px;
			text-align: center;
			text-transform: uppercase;
		}

		.editorial {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.article {
				padding: 64px 30px;
				font-family: "Lato", sans-serif;
				font-size: 17px;
				line-height: 1.8;
				font-style: normal;
				color: #000;
				font-weight: 400;
				max-width: 620px;
				position: relative;

				@media only screen and (max-width: 1200px) {
					margin: 0 auto;
				}

				@media only screen and (max-width: 767px) {
					padding: 30px 30px 50px;
					max-width: 500px;
				}

				a {
					font-weight: 600;
					letter-spacing: 0.2px;
					color: #cc8342;

					&:hover {
						text-decoration: underline;
					}

				}

				.emphasized {
					letter-spacing: 0.1px;
					font-style: italic;
					padding-right: 2px;
				}
			}
		}
	}
</style>
