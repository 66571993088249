<template>
	<div class="editorial-navigation">
		<button
			v-if="collapsible"
			class="editorial-navigation__open-button"
			@click="toggleLinks"
		>
			<span>Articles</span>
			<Icon
				v-if="linksOpen"
				class="editorial-navigation__icon"
				:icon="['fas', 'chevron-up']"
			/>
			<Icon
				v-else
				class="editorial-navigation__icon"
				:icon="['fas', 'chevron-down']"
			/>
		</button>
		<UikCollapse ref="collapse">
			<div v-if="linksOpen" class="editorial-navigation__links-container">
				<div v-if="!collapsible" class="editorial-navigation__links-container__title">Articles</div>
				<div class="editorial-navigation__links-container__content">
					<router-link
						v-for="link in $options.links"
						:key="link.path"
						:to="link.path"
						@click.native="collapsed = true"
					>{{ link.title }}</router-link>
				</div>
			</div>
		</UikCollapse>
		<hr v-if="linksOpen && collapsible && activeLink && activeLink.chapters && activeLink.chapters.length > 0" />
		<div
			v-if="linksOpen && collapsible && activeLink && activeLink.chapters && activeLink.chapters.length > 0"
			class="editorial-navigation__chapters-title"
		>Chapters</div>
		<UikCollapse v-if="activeLink && activeLink.chapters && activeLink.chapters.length > 0" ref="collapse">
			<div v-if="linksOpen" class="editorial-navigation__chapters-container">
				<div v-if="!collapsible" class="editorial-navigation__chapters-container__title">Chapters</div>
				<div class="editorial-navigation__chapters-container__content">
					<router-link
						v-for="link in activeLink.chapters"
						:key="link.path"
						:to="link.path"
						@click.native="collapsed = true"
					>{{ link.title }}</router-link>
				</div>
			</div>
		</UikCollapse>
	</div>
</template>

<script>
import UikCollapse from "@/components/UikCollapse.vue"

export default {
	links: [
		{
			path: "/editorial/semantics",
			title: "Semantics"
		},
		{
			path: "/editorial/raison-detre",
			title: "Raison D'être"
		},
		{
			path: "/editorial/a-terrible-secret",
			title: "A Terrible Secret"
		},
		{
			path: "/editorial/lars-and-sven",
			title: "Lars & Sven"
		},
		{
			path: "/editorial/hood-vs-lars",
			title: "Hood vs. Lars"
		},
		{
			path: "/editorial/kavarna-tiskarna",
			title: "Kavarna Tiskarna"
		},
		{
			path: "/editorial/a-royal-mess",
			title: "A Royal Mess",
			chapters: [
				{
					path: "/editorial/a-royal-mess#dark-secrets-and-historical-revisionism",
					title: "Dark Secrets and Historical Revisionism"
				},
				{
					path: "/editorial/a-royal-mess#digital-forensics",
					title: "Digital Forensics"
				},
				{
					path: "/editorial/a-royal-mess#chaos-revealed",
					title: "Chaos Revealed"
				},
				{
					path: "/editorial/a-royal-mess#conclusion-for-the-moment",
					title: "Conclusion (For The Moment)"
				}
			]
		},
		{
			path: "/editorial/a-royal-mess-2",
			title: "A Royal Mess 2: The Reckoning",
			chapters: [
				{
					path: "/editorial/a-royal-mess-2#an-inauspicious-prelude",
					title: "An Inauspicious Prelude"
				},
				{
					path: "/editorial/a-royal-mess-2#the-plot-thickens",
					title: "The Plot Thickens"
				},
				{
					path: "/editorial/a-royal-mess-2#the-story-so-far",
					title: "The Story So Far"
				},
				{
					path: "/editorial/a-royal-mess-2#the-campaign-of-torment",
					title: "The Campaign of Torment"
				},
				{
					path: "/editorial/a-royal-mess-2#an-uncertain-conclusion",
					title: "An Uncertain Conclusion"
				}
			]
		}
	],
	components: {
		UikCollapse
	},
	props: {
		collapsible: { type: Boolean, default: false }
	},
	beforeRouteEnter () {
		this.collapsed = true
	},
	data () {
		return {
			collapsed: true
		}
	},
	computed: {
		activeLink () {
			return this.$options.links.find(link => link.path === this.$route.path)
		},
		linksOpen () {
			if (!this.collapsible) return true
			return !this.collapsed
		}
	},
	watch: {
		collapsible () {
			this.collapsed = true
		}
	},
	methods: {
		toggleLinks () {
			this.collapsed = !this.collapsed
		}
	}
}
</script>

<style lang="scss">
	.editorial-navigation {
		display: flex;
		flex-direction: column;
		width: 420px;
		min-width: 170px;
		position: sticky;
		z-index: 1;
		background-color: #FEFEFE;
		overflow-x: hidden;
		padding: 10px;
		line-height: 22px;
		height: calc(100vh - 86px);
		box-sizing: border-box;
		top: 86px;
		// left: 100%;
		box-shadow: 0 0 13px #0000001f;

		@media only screen and (max-width: 1200px) {
			display: block;
			margin: 0 auto;
			margin-top: 40px;
			height: inherit;
			position: unset;
			padding: 0;
			border-radius: 5px;
			box-shadow: 0 0 13px #0000001f;
		}

		@media only screen and (max-width: 767px) {
			display: block;
			margin: 0 auto;
			height: inherit;
			position: unset;
			border-radius: 0;
			width: 100%;
			min-width: 100%;
			padding: 0;
			box-shadow: 0 0 13px #0000001f;
		}

		a {
			color: #000;
			font-style: italic;
			padding: 8px 25px;

			&:hover {
				background-color: #F5BB8A;
				border-radius: 5px;
			}

			@media only screen and (max-width: 1200px) {
				padding: 12px 25px;

				&:hover {
					border-radius: 0;
				}
			}

			@media only screen and (max-width: 767px) {
				padding: 16px 40px;
			}
		}

		hr {
			width: 100%;
			margin: 6px auto 6px auto;
			padding: 0;
			opacity: 0.5;
			display: none;
		}

		.editorial-navigation__open-button {
			background: none;
			border: none;
			font-size: 15px;
			letter-spacing: 1px;
			padding: 20px;
			text-transform: uppercase;
			width: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;
			color: #2c3e50;
			font-family: "Nunito Sans", sans-serif;

			&:hover {
				background: #F5BB8A;
				border-radius: 5px 5px 0 0;

				@media only screen and (max-width: 767px) {
					border-radius: 0;
				}
			}

			@media only screen and (max-width: 1200px) {
				font-weight: 600;
				justify-content: space-between;
			}

			@media only screen and (max-width: 767px) {
				padding: 20px 30px;
				height: 80px;
			}
		}

		.editorial-navigation__chapters-title {
			background: none;
			border: none;
			font-size: 15px;
			letter-spacing: 1px;
			padding: 20px 20px 20px 20px;
			text-transform: uppercase;
			width: 100%;
			display: flex;
			color: #2c3e50;
			font-weight: 600;
			font-family: "Nunito Sans", sans-serif;

			@media only screen and (max-width: 1200px) {
				font-weight: 600;
				justify-content: left;
				margin-top: 10px;
			}

			@media only screen and (max-width: 767px) {
				padding: 20px 32px 20px 32px;
				font-weight: 600;
				justify-content: left;
				margin-top: 20px;
			}
		}

		.editorial-navigation__links-container,
		.editorial-navigation__chapters-container {
			display: flex;
			flex-direction: column;
			padding-bottom: 8px;

			.editorial-navigation__links-container__title,
			.editorial-navigation__chapters-container__title {
				font-size: 15px;
				text-transform: uppercase;
				padding: 20px 0 20px 20px;
				letter-spacing: 1px;
				font-weight: 600;
				font-family: "Nunito Sans", sans-serif;
			}

			.editorial-navigation__chapters-container__title {
				margin-top: 20px;
			}

			.editorial-navigation__links-container__content,
			.editorial-navigation__chapters-container__content {
				display: contents;
			}

			.editorial-navigation__links-container__content {
				.router-link-active {
					background-color: #ef9443;
					border-radius: 5px;
					padding: 8px 25px;
					font-weight: 600;

					@media only screen and (max-width: 1200px) {
						border-radius: 0;
						padding: 12px 25px;
					}

					@media only screen and (max-width: 767px) {
						border-radius: 0;
						padding: 16px 40px;
					}
				}
			}

			.editorial-navigation__chapters-container__content {

				a {
					font-style: italic;
				}
			}
		}
	}
</style>
